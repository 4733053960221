import React, { Component } from 'react'

import Img from 'react-image'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '0.5em',
    overflow: 'hidden',
    height: '100%',
    boxShadow: '10px 10px 15px 0px rgba(37,53,60,0.2)',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      marginLeft: theme.spacing(7),
      marginRight: theme.spacing(7),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    height: '200px',
  },
  title: {
    margin: '0.2em 0',
    textAlign: 'center',
    fontSize: '1.2rem',
    minHeight: '60px',
  },
  list: {
    listStyleType: 'none',
    textAlign: 'center',
    padding: 0,
    margin: '0.5em 0',
  },
  phone: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
  },
}))

const Club = ({ club }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <Img className={classes.image} src={club.image} />
      <div style={{ padding: '1em' }}>
        <Typography component="h4" variant="h6" className={classes.title}>
          <strong>{club.name}</strong>
        </Typography>
        <ul className={classes.list}>
          <li>
            <Typography variant="body2">{club.address}</Typography>
          </li>
          <li>
            <Typography variant="body2">{club.city}</Typography>
          </li>
          <li>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.phone}
              dangerouslySetInnerHTML={{
                __html: club.phone,
              }}
            >
            </Typography>
          </li>
        </ul>
      </div>
    </Paper>
  )
}

Club.defaultProps = {}

export default Club
