import React from 'react'
import clsx from 'clsx'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '100vh',
    height: '0px',
  },
  rootSecondary: {
    minHeight: '50vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    zIndex: 2,
    maxWidth: '60%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      maxWidth: '96%',
    },
  },
  overlay: {
    background: theme.palette.primary.main,
    position: 'absolute !important',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: -2,
  },
  background: {
    position: 'absolute !important',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: -1,
    overflow: 'hidden',
  },
  logo: {
    display: 'block',
    width: '100%',
  },
  waves: {
    position: 'absolute !important',
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
  },
  title: {
    fontWeight: 800,
    marginBottom: theme.spacing(5),
    color: theme.palette.primary.main,
  },
  titleSecondary: {
    marginTop: theme.spacing(7),
  },
  subtitle: {
    color: '#b6c9d2',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      marginBottom: theme.spacing(5),
    },
  },
}))

const Hero = ({ title, subtitle, children, variant }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "home/bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <div
        className={clsx(
          classes.root,
          variant === 'secondary' && classes.rootSecondary
        )}
      >
        <Img
          fluid={data.backgroundImage.childImageSharp.fluid}
          className={classes.background}
          objectFit="cover"
          objectPosition="50% 50%"
        />
        <div
          className={classes.content}
          style={{
            textTransform: variant === 'primary' ? 'none' : 'uppercase',
          }}
        >
          <Typography
            variant="h1"
            className={clsx(
              classes.title,
              variant === 'secondary' && classes.titleSecondary
            )}
          >
            {title}
          </Typography>
          <Typography
            variant="h6"
            color="textSecondary"
            className={classes.subtitle}
          >
            {subtitle}
          </Typography>
          {children}
        </div>
        {variant === 'primary' && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0, 0, 736, 414"
            className={classes.waves}
          >
            <g id="Layer_1">
              <path
                d="M27,389 C27,389 224.4,292.811 337,336 C410,364 422,403.667 565,403.667 C719,403.667 763,369.644 763,369 C763,368 763,420 763,420 L27,420 C27,420 27,390.333 27,389 z"
                fill="#eee"
                fillOpacity="0.069"
                id="Fond1"
              />
              <path
                d="M0,386 C0,386 197.4,289.811 310,333 C383,361 395,400.667 538,400.667 C692,400.667 736,366.644 736,366 C736,365 736,417 736,417 L0,417 C0,417 0,387.333 0,386 z"
                fill="#eee"
                fillOpacity="0.225"
                id="Fond2"
              />
              <path
                d="M0,386 C0,386 197.4,309.811 310,353 C383,381 395,403.667 538,403.667 C694,403.667 736,378.644 736,378 C736,377 736,420 736,420 L0,420 C0,420 0,387.333 0,386 z"
                fill="#E7F1FE"
              />
            </g>
          </svg>
        )}
      </div>
    </React.Fragment>
  )
}

Hero.defaultProps = {
  minHeight: '80vh',
  variant: 'primary',
}

export default Hero
