import React, { useState, useCallback } from 'react'
import clsx from 'clsx'
import Img from 'react-image'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    width: '80%',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9,
  },
  name: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  card: {
    position: 'relative',
    width: '100%',
    '&::after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
  },
  imageContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: '#fff',
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    outline: 'none',
  },
  name: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
}))

const PartnersCard = ({ partner }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.imageContainer}>
          <Link
            href={partner.url}
            target="_blank"
            underline="none"
            title={partner.name}
          >
            <Img src={partner.logo} className={classes.image} />
          </Link>
        </div>
      </div>
      <Typography variant="subtitle2" component="h6" className={classes.name}>
        <Link
          href={partner.url}
          target="_blank"
          underline="none"
          title={partner.name}
        >
          <strong>{partner.name}</strong>
        </Link>
      </Typography>
    </div>
  )
}

PartnersCard.defaultProps = {}

export default PartnersCard
