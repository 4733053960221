import React, { useState, useCallback } from 'react'
import clsx from 'clsx'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: '10px 10px 15px 0px rgba(37,53,60,0.2)',
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(7),
      marginRight: theme.spacing(7),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9,
  },
  name: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  button: {
    boxShadow: 'none',
    background: '#f4f4f4',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}))

const TeamCard = ({ instructor, variant }) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleToggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen)
  })

  return (
    <React.Fragment>
      <Dialog
        open={isModalOpen}
        onClose={handleToggleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <strong>{instructor.name}</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ul>
              {instructor.description.map((d, i) => (
                <li key={'description-' + instructor.name + '-' + i}>{d}</li>
              ))}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleToggleModal}
            color="default"
            variant="contained"
            size="small"
            className={classes.button}
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={instructor.image}
          title={instructor.name}
        />
        <CardHeader
          title={instructor.name}
          titleTypographyProps={{
            variant: 'subtitle2',
            className: classes.name,
          }}
          subheader={instructor.caption}
          subheaderTypographyProps={{
            variant: 'caption',
          }}
        />
        {variant === 'primary' && (
          <CardActions>
            <Button
              size="small"
              onClick={handleToggleModal}
              className={classes.button}
            >
              <small>En savoir plus</small>
            </Button>
          </CardActions>
        )}
      </Card>
    </React.Fragment>
  )
}

TeamCard.defaultProps = {}

export default TeamCard
