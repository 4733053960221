import React, { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import MaterialButton from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '10px 10px 15px 0px rgba(37,53,60,0.1)',
    padding: theme.spacing(2),
    fontWeight: 600,
    borderRadius: '6px',
    minWidth: '250px',
  },
  primary: {
    color: theme.palette.secondary.main,
  },
  secondary: {
    color: theme.palette.primary.main,
    background: '#f4f4f4',
  },
}))

const Button = props => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const { children, variant, className, ...other } = props

  return (
    <MaterialButton
      {...other}
      variant="contained"
      className={clsx(
        classes.root,
        variant === 'primary' && classes.primary,
        variant === 'secondary' && classes.secondary,
        className
      )}
      color={variant === 'primary' ? 'primary' : 'default'}
    >
      {children}
    </MaterialButton>
  )
}

Button.defaultProps = {
  variant: 'primary',
}

export default Button
