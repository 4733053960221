import React from 'react'
import clsx from 'clsx'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import Slider from 'react-slick'
import PartnersCard from './PartnersCard'
const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  rootPrimary: {
    marginTop: theme.spacing(10),
  },
}))

const responsive = [
  { breakpoint: 768, settings: { slidesToShow: 1 } },
  { breakpoint: 1024, settings: { slidesToShow: 2 } },
]

const Partners = ({ variant }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const partners = t('home:partners.items', {
    returnObjects: true,
  })

  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} xs={12}>
        <Slider
          dots={true}
          adaptiveHeight={false}
          slidesToShow={4}
          arrows={false}
          slidesToScroll={1}
          responsive={responsive}
          autoplay={true}
        >
          {partners.map(partner => (
            <PartnersCard partner={partner} key={partner.logo} />
          ))}
        </Slider>
      </Grid>
    </Grid>
  )
}

Partners.defaultProps = {
  variant: 'secondary',
}

export default Partners
